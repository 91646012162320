import UserData from '../../../../fakeUsers.json'

const TransactionsTable = () => {
  return (
    <div className='col-12 '>
      <div className='text-end w-100 text-gray-600 d-lg-none'>slide to view other columns →</div>
      <div className='card col-12 table-responsive'>
        <table className='last-two-columns table table-row-dashed  dataTable   table-bordered table-rounded table-striped  gy-7 gs-7'>
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              <th>Name</th>
              <th>Position</th>
              <th>Office</th>
              <th>Age</th>
              <th>Loans</th>
              <th>Number of Children</th>
              <th>Married</th>
              <th className='text-primary'>Score</th>
              <th className='text-primary'>Class</th>
            </tr>
          </thead>

          <tbody>
            {UserData?.map((user: any): any => (
              <tr>
                <td>{user.Name}</td>
                <td>{user.Position}</td>
                <td>{user.Office}</td>
                <td>{user.Age}</td>
                <td>{user.Loans}</td>
                <td>{user.NumberOfChildren}</td>
                <td>{user.Married}</td>
                <td className={`${(user.Class).split(' ').join('-')}`}>{user.Score}</td>
                <td className={`${(user.Class).split(' ').join('-')}`}>{user.Class}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default TransactionsTable
