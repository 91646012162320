import React from 'react'
type props = {
  value: number
}
const PaymentDetails = (props : props) => {
    const value = props.value || 25
  return (
    <div className='card col-12 col-md-6 p-4 d-flex flex-column justify-content-around'>
      <div className='d-flex flex-column gap-10'>
        <div className='p-4 border rounded d-flex flex-column align-items-center text-center my-10 my-sm-0 mx-auto mw-150px'>
          <h3 className='text-danger mb-5'>Total price</h3>
          <div className='btn btn-sm btn-light text-success' style={{cursor: 'none'}}>
            {value}$
          </div>
        </div>
        <h5 className='text-primary mx-auto'>
          You will pay total of <span className='text-success'>{value}$</span> for{' '}
          <u><span className='text-danger'>{value *2}</span> User request</u>
        </h5>
      </div>

      <img
        className='h-50px h-lg-150px'
        src='media/svg/illustrations/payment-illustration.svg'
        alt='payment'
      />
    </div>
  )
}

export default PaymentDetails
