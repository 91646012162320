import CopyBox from "../../../utils/copyBox"


type APIKeyType = {
  APIkey: string,
  label: string
}

const APIKey = ({APIkey, label}: APIKeyType) => {
  return <CopyBox value={APIkey} label={label} />
}

export default APIKey
