import Slider from 'rc-slider'

import 'rc-slider/assets/index.css'
import {useState} from 'react'
import {Link} from 'react-router-dom'

const PricingPage = () => {
  const [value, setValue] = useState(50)

  const onSliderChange = (value: number) => {
    setValue(value * 0.5)
  }
  return (
    <div className='d-flex flex-column justify-content-evenly flex-grow-1 gap-5 '>
      <div className='d-flex justify-content-around gap-7 flex-column flex-lg-row'>
        {/* left card */}
        <div className='d-flex flex-column col-xxl-6 col-lg-6  card card-custom card-flush p-6 px-sm-8'>
          <div className='d-flex flex-sm-row flex-column justify-content-between col mb-10 xxl-6'>
            <div className='col-12 col-sm-4 d-flex flex-column justify-content-center text-center text-sm-start mb-10'>
              <h1 className='font-weight-bold mb-5'>Free to start</h1>
              <div>
                <span className='text-primary font-weigth-bold h1'>$0</span> up to
              </div>
            </div>
            <div className='col-12 col-sm-7 d-flex flex-row gap-3 justify-content-around ps-lg-0 ps-5 ps-lg-5'>
              <div className='d-flex col flex-column'>
                <h6 className='text-gray-600 pb-2'>Bulk Upload</h6>
                <p>
                  <span className='bullet me-2'></span>1 bulk upload <br />
                  <span className='bullet me-2'></span> up to 50 users
                </p>
              </div>
              <div className='d-flex col flex-column align-items-center'>
                <h6 className='text-gray-600 pb-2'>API Usage</h6>
                <p>
                  <span className='bullet me-2'></span> up to 50 users
                </p>
              </div>
            </div>
          </div>
          <hr className='mb-10 w-75 mx-auto' />
          <div className='d-flex flex-sm-row flex-column justify-content-between col mb-10 xxl-6'>
            <div className='col-12 col-sm-4 d-flex flex-column justify-content-center text-center text-sm-start mb-10'>
              <h1 className='font-weight-bold mb-5'>Then</h1>
              <div>
                <span className='text-primary font-weigth-bold h1'>$0.50</span> per
              </div>
            </div>
            <div className='col-12 col-sm-7 d-flex flex-row gap-3 justify-content-around ps-lg-0 ps-5 ps-lg-5'>
              <div className='d-flex col flex-column '>
                <h6 className='text-gray-600 pb-2 '>Bulk Upload</h6>
                <p>
                  <span className='bullet me-2'></span>user inside sheet
                </p>
              </div>
              <div className='d-flex col flex-column align-items-center'>
                <h6 className='text-gray-600 pb-2'>API Usage</h6>
                <p>
                  <span className='bullet me-2'></span>user in API request
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* right card */}
        <div className='card card-custom card-flush p-xxl-10 p-6 col-lg-6 pb-5 col-xxl-6 d-flex flex-column justify-content-between'>
          <div className='d-flex flex-column flex-lg-row justify-content-between align-items-center'>
            <div className='pe-lg-10 w-100 text-center'>
              <h1 className='font-weight-bold '>Calculate Your Price</h1>
              <div className='py-5 h4'>
                we are offering our service as{' '}
                <span className='text-primary h3 d-block d-sm-inline'>
                  <u> pay as you use</u>
                </span>
              </div>

              <div className='col-xxl-12 mb-10'>
                <div className='mb-10 text-gray-600'>
                  to estimate the price kindly use this slider
                </div>
                <div className='d-flex flex-row justify-content-between'>
                  <Slider
                    min={50}
                    max={500}
                    defaultValue={50}
                    marks={{
                      50: 50,
                      100: 100,
                      150: 150,
                      200: 200,
                      250: 250,
                      300: 300,
                      350: 350,
                      400: 400,
                      450: 450,
                      500: 500,
                    }}
                    step={50}
                    //@ts-ignore
                    onChange={onSliderChange}
                  />
                  <div className='mx-5 d-none d-sm-block text-gray-600'>(users)</div>
                </div>
              </div>
              <div className='d-sm-none text-gray-600 text-end w-100'>(users)</div>
            </div>

            <div className='p-4 border rounded d-flex flex-column align-items-center text-center my-10 my-sm-0'>
              <h3 className='text-danger mb-5'>Total price</h3>
              <div className='btn btn-sm btn-light text-primary' style={{cursor: 'none'}}>
                {value}$
              </div>
            </div>
          </div>
          <div className='d-flex  mx-auto w-50'>
            <Link to='/payment' state={{totalPrice: {value}}} className='col btn btn-primary'>
              Purchase
            </Link>
          </div>
        </div>
      </div>

      <div className='card px-6 col-xxl-6  col-lg-8  py-10 mx-auto d-flex flex-sm-row align-items-center justify-content-between gap-15'>
        <div className='d-flex flex-column gap-5 justify-content-between h-100 text-center'>
          <h1>How is it working?</h1>
          <p className='pb-sm-5'>
            When you submit user info either via excel sheet or using our API we run a smart AI
            service at out backed that calculate exactly the level and the credits of this user
          </p>
        </div>
        <img className='w-50' src='media/misc/AI-pricing.svg' alt='AI' />
      </div>
    </div>
  )
}

export default PricingPage
