import UserData from '../../../../fakeUsers.json'

const DataTable = ({setIsSubmit}:any) => {
  return (
    <div className='col-12'>
      <div className='text-end w-100 text-gray-600 d-lg-none'>slide to view other columns →</div>
      <div className='card col-12 table-responsive'>
        <table className='table table-row-dashed  dataTable  border-secondary table-bordered table-rounded table-striped border gy-7 gs-7'>
          <thead>
            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
              <th>Name</th>
              <th>Position</th>
              <th>Office</th>
              <th>Age</th>
              <th>Start date</th>
              <th>Salary</th>
            </tr>
          </thead>

          <tbody>
            {UserData?.map((user: any): any => (
              <tr>
                <td>{user.Name}</td>
                <td>{user.Position}</td>
                <td>{user.Office}</td>
                <td>{user.Age}</td>
                <td>{user.StartDate}</td>
                <td>{user.Salary}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button className='btn btn-primary ms-auto d-block my-5 px-20' onClick={() => setIsSubmit(true)}>
        submit
      </button>
    </div>
  )
}

export default DataTable
