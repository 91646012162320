import {useState} from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import {copyBox} from './uitls-Interfaces'

const CopyBox = ({value,label, options}: copyBox) => {
  const [copied, setCopied] = useState<any>({[value]:false})
  return (
    <CopyToClipboard text={value} onCopy={() => setCopied({[value]:true})}>
      <div className='col-sm-10 col-12'>
        <div className='input-group mb-10'>
          <label className='col-form-label text-right col-lg-2 col-sm-12 w-60px w-md-80px me-2'>
            {label}
          </label>
          <input type='text' className='form-control' id={label} value={value} />
          <div className='input-group-append'>
            <a href='/#' className='btn btn-secondary' onClick={(e) => e.preventDefault()}>
              <i className='la la-copy'></i>
              {copied[value]  && <span>copied</span>}
            </a>
          </div>
        </div>
      </div>
    </CopyToClipboard>
  )
}

export default CopyBox
