/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
// import {ThemeModeSwitcher} from '../../../_metronic/partials'
// import clsx from 'clsx'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}

      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 px-10 py-4 order-2 order-lg-1'>
        {/* begin::Form */}
        {/* <div className={clsx('app-navbar-item', 'switch')}>
              <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
            </div> */}

        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px p-5 pb-0'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
        style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})`}}
      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
          {/* begin::Logo */}
          <Link to='/' className='mb-12'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/custom-1.png')} className='h-150px' />
          </Link>
          {/* end::Logo */}

          {/* begin::Image */}
          {/* <img
            className='mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20'
            src={toAbsoluteUrl('/media/misc/auth-screens.png')}
            alt=''
          /> */}
          {/* end::Image */}

          {/* begin::Title */}
          <h1 className='text-white fs-2qx fw-bolder text-center mb-7'>
            Fast, Efficient and Productive
          </h1>
          {/* end::Title */}
          {/* begin::Footer */}
          <div className='d-flex flex-center flex-wrap px-5 pb-7 position-absolute bottom-0 mb-20'>
            {/* begin::Links */}
            <div className='d-flex fw-semibold text-white fs-base'>
              <a href='#' className='px-5 text-white' target='_blank'>
                Terms
              </a>

              <a href='#' className='px-5 text-white' target='_blank'>
                Plans
              </a>

              <a href='#' className='px-5 text-white' target='_blank'>
                Contact Us
              </a>
            </div>

            {/* end::Links */}
          </div>
          {/* end::Footer */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

export {AuthLayout}
