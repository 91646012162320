import React from 'react'
import CreditCard from './components/creditCard/creditCard'
import PaymentDetails from './components/paymentDetails/paymentDetails'
import {useLocation} from 'react-router-dom'
type totalPrice = {
  value: number
}
type locationProps = {
  totalPrice: totalPrice
}

const PaymentsPage = () => {
  const location = useLocation()
  const state = location.state as locationProps

  console.log('-----'.repeat(25))
  console.log(state)
  console.log('-----'.repeat(25))

  return (
    <div className='d-flex flex-column flex-sm-row justify-content-between col-12 gap-sm-30'>
      <PaymentDetails value={state?.totalPrice?.value} />
      <CreditCard />
    </div>
  )
}

export default PaymentsPage
