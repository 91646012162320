const UserInfo = ({user}: any) => {
  const userAvatarImg = `media/avatars/300-${Math.floor(Math.random() * 10) + 1}.jpg`
  return (
    user && (
      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className=' show d-block' id='add_user' aria-modal='true'>
              <div className='modal-dialog modal-dialog-centered mw-650px'>
                <div className='modal-content'>
                  <div className='image-input image-input-outline'>
                    <div
                      className='image-input-wrapper w-125px h-125px mx-auto'
                      style={{backgroundImage: `url('${userAvatarImg}')`, backgroundSize: 'cover'}}
                    ></div>
                  </div>
                  {/* begin::Modal body */}
                  <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                    <div className='infoBox mb-10'>
                      <div className='d-flex align-items-center flex-wrap d-grid gap-2 justify-content-center'>
                        <div className='d-flex align-items-center me-5 me-xl-13'>
                          <div className='symbol symbol-30px symbol-circle me-3'>
                            <img
                              src='/media/icons/duotune/general/gen015.svg'
                              className='text-primary'
                              alt='img'
                              style={{
                                filter: 'invert(0.5) sepia(1) saturate(5) hue-rotate(175deg)',
                                cursor: 'default',
                              }}
                            />
                          </div>

                          <div className='m-0'>
                            <span className='fw-semibold text-gray-400 d-block fs-8'>Score</span>
                            {user.Score}
                          </div>
                        </div>

                        <div className='d-flex align-items-center'>
                          <div
                            className='symbol symbol-30px symbol-circle me-3'
                            style={{cursor: 'default'}}
                          >
                            <span className='symbol-label bg-success'>
                              <span className='svg-icon svg-icon-5 svg-icon-white'>
                                <svg
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M18 21.6C16.6 20.4 9.1 20.3 6.3 21.2C5.7 21.4 5.1 21.2 4.7 20.8L2 18C4.2 15.8 10.8 15.1 15.8 15.8C16.2 18.3 17 20.5 18 21.6ZM18.8 2.8C18.4 2.4 17.8 2.20001 17.2 2.40001C14.4 3.30001 6.9 3.2 5.5 2C6.8 3.3 7.4 5.5 7.7 7.7C9 7.9 10.3 8 11.7 8C15.8 8 19.8 7.2 21.5 5.5L18.8 2.8Z'
                                    fill='currentColor'
                                  ></path>
                                  <path
                                    opacity='0.3'
                                    d='M21.2 17.3C21.4 17.9 21.2 18.5 20.8 18.9L18 21.6C15.8 19.4 15.1 12.8 15.8 7.8C18.3 7.4 20.4 6.70001 21.5 5.60001C20.4 7.00001 20.2 14.5 21.2 17.3ZM8 11.7C8 9 7.7 4.2 5.5 2L2.8 4.8C2.4 5.2 2.2 5.80001 2.4 6.40001C2.7 7.40001 3.00001 9.2 3.10001 11.7C3.10001 15.5 2.40001 17.6 2.10001 18C3.20001 16.9 5.3 16.2 7.8 15.8C8 14.2 8 12.7 8 11.7Z'
                                    fill='currentColor'
                                  ></path>
                                </svg>
                              </span>
                            </span>
                          </div>

                          <div className='m-0'>
                            <span className='fw-semibold text-gray-400 d-block fs-8'>Class</span>
                            <span className='fw-bold text-gray-800 fs-7'>Class A</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className='text-primary border border-gray-700 mb-10' />
                    <div className='table d-flex flex-column text-center w-60 mx-auto gap-5'>
                      <tr className='d-flex gap-25 justify-content-between'>
                        <td className='fw-bold'>Name: </td>
                        <td>{user.Name}</td>
                      </tr>
                      <tr className='d-flex gap-25 justify-content-between'>
                        <td className='fw-bold'>Position: </td>
                        <td>{user.Position}</td>
                      </tr>

                      <tr className='d-flex gap-25 justify-content-between'>
                        <td className='fw-bold'>Office: </td>
                        <td>{user.Office}</td>
                      </tr>
                      <tr className='d-flex gap-25 justify-content-between'>
                        <td className='fw-bold'>Age: </td>
                        <td>{user.Age}</td>
                      </tr>
                      <tr className='d-flex gap-25 justify-content-between'>
                        <td className='fw-bold'>Start date: </td>
                        <td>{user.StartDate}</td>
                      </tr>
                      <tr className='d-flex gap-25 justify-content-between'>
                        <td className='fw-bold'>Salary: </td>
                        <td>{user.Salary}</td>
                      </tr>
                    </div>
                  </div>
                  {/* end::Modal body */}
                </div>
                {/* end::Modal content */}
              </div>
              {/* end::Modal dialog */}
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
              <button type='button' className='btn btn-primary'>
                view more
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default UserInfo
