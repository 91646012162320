import CopyBox from "../../../utils/copyBox"


type APISecretType = {
  APIsecret: string,
  label: string
}

const APISecret = ({APIsecret,label}: APISecretType) => {
  return <CopyBox value={APIsecret} label={label} />
}

export default APISecret
