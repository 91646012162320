import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}verify-token`
export const LOGIN_URL = `${API_URL}login`
export const REGISTER_URL = `${API_URL}register`
export const REQUEST_PASSWORD_URL = `${API_URL}forgot_password`

// Server should return AuthModel
export function login(username: string, password: string) {
  return axios.post<AuthModel>(
    LOGIN_URL,
    {
      username: username,
      password: password,
    },
  )
}

// Server should return AuthModel
export function register(
  email: string,
  companyName: string,
  password: string,
  changepassword?: string
) {
  return axios.post(REGISTER_URL, {
    email:email,
    username: email,
    password:password,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  // console.log(token);
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    headers: {Authorization: `Token ${token}`},
  })
}
