import React, {useEffect, useState} from 'react'
import DataTable from './CSVtables/dataTable'
import PlaceHolderTable from './CSVtables/placeHolderTable'
import LoadingResults from './loadingResults/loadingResults'
import UploadCSV from './uploadCSV/uploadCSV'

const DataCSVTable = () => {
  const [data, setData] = useState([])
  const [IsSubmit, setIsSubmit] = useState(false)

  return (
    <div className='d-flex flex-column align-items-center w-100'>
      {!IsSubmit ? (
        <>
          <UploadCSV setData={setData} />
          {data.length > 0 ? <DataTable setIsSubmit={setIsSubmit} /> : <PlaceHolderTable />}
        </>
      ) : (
        <LoadingResults />
      )}
    </div>
  )
}

export default DataCSVTable
