
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const PlaceHolderTable = () => {
  return (
    <div className='col-12 col-md-11 position-relative'>
      <div
        className='position-absolute top-50 start-50 h6 text-primary d-flex flex-column align-items-center gap-5 card p-5 border border-gray-500'
        style={{zIndex: '1', transform: 'translate(-50%,-20%)'}}
      >
        <img
          className='px'
          src={toAbsoluteUrl('/media/icons/duotune/files/fil010.svg')}
          alt='upload icon'
        />
        Upload CSV file first to view the data
      </div>

      <div className='card col-12 table-responsive'>
        <table className='table layout-fixed table-row-dashed  dataTable  border-secondary table-bordered table-rounded table-striped border gy-7 gs-7'>
          <thead>
            <tr className='fw-bold fs-6 text-gray-500 border-bottom border-gray-200'>
              <th>Name</th>
              <th>Position</th>
              <th>Office</th>
              <th>Age</th>
              <th style={{whiteSpace: 'nowrap'}}>Start date</th>
              <th>Salary</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <div className=' rounded h-10px bg-gray-200'></div>
              </td>
              <td>
                <div className=' rounded h-10px bg-gray-200'></div>
              </td>
              <td>
                <div className=' rounded h-10px bg-gray-200'></div>
              </td>
              <td>
                <div className=' rounded h-10px bg-gray-200'></div>
              </td>
              <td>
                <div className=' rounded h-10px bg-gray-200'></div>
              </td>
              <td>
                <div className=' rounded h-10px bg-gray-200'></div>
              </td>
            </tr>
            <tr>
              <td>
                <div className=' rounded h-10px bg-gray-200'></div>
              </td>
              <td>
                <div className=' rounded h-10px bg-gray-200'></div>
              </td>
              <td>
                <div className=' rounded h-10px bg-gray-200'></div>
              </td>
              <td>
                <div className=' rounded h-10px bg-gray-200'></div>
              </td>
              <td>
                <div className=' rounded h-10px bg-gray-200'></div>
              </td>
              <td>
                <div className=' rounded h-10px bg-gray-200'></div>
              </td>
            </tr>
            <tr>
              <td>
                <div className=' rounded h-10px bg-gray-200'></div>
              </td>
              <td>
                <div className=' rounded h-10px bg-gray-200'></div>
              </td>
              <td className='w-20px h-10px color-gray-600'></td>
              <td>
                <div className=' rounded h-10px bg-gray-200'></div>
              </td>
              <td>
                <div className=' rounded h-10px bg-gray-200'></div>
              </td>
              <td>
                <div className=' rounded h-10px bg-gray-200'></div>
              </td>
            </tr>
            <tr>
              <td>
                <div className=' rounded h-10px bg-gray-200'></div>
              </td>
              <td>
                <div className=' rounded h-10px bg-gray-200'></div>
              </td>
              <td>
                <div className=' rounded h-10px bg-gray-200'></div>
              </td>
              <td>
                <div className=' rounded h-10px bg-gray-200'></div>
              </td>
              <td>
                <div className=' rounded h-10px bg-gray-200'></div>
              </td>
              <td>
                <div className=' rounded h-10px bg-gray-200'></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default PlaceHolderTable
