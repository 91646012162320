/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import UserData from '../../../../../fakeUsers.json'
import {UserEditModal} from '../../../../../app/modules/apps/user-management/users-list/user-edit-modal/UserEditModal'
import {UserEditModalFormWrapper} from '../../../../../app/modules/apps/user-management/users-list/user-edit-modal/UserEditModalFormWrapper'
import {UserEditModalHeader} from '../../../../../app/modules/apps/user-management/users-list/user-edit-modal/UserEditModalHeader'
import UserInfo from '../../../../../app/modules/modal/userInfo/userInfo'
import {KTSVG, toAbsoluteUrl} from '../../../../helpers'
import {useState} from 'react'

type Props = {
  className: string
  description: string
  icon: boolean
  stats: number
  labelColor: string
  textColor: string
}

const CardsWidget7 = ({className, description, icon, stats, labelColor, textColor}: Props) => {
  const [user, setUser] = useState<any>()
  return (
    <div className={`card card-flush ${className}`}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='card-title d-flex flex-column'>
            {/* <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{stats}</span> */}
            <span className='text-gray-800 fw-bold'>{description}</span>
          </div>
        </div>
      </div>
      <div className='card-body d-flex flex-column justify-content-end pe-0'>
        <span className='fs-6 fw-bolder text-gray-800 d-block mb-2'>employees</span>
        <div className='symbol-group symbol-hover flex-nowrap'>
          {UserData.map((user, index) => (
            <div
              className='symbol symbol-35px symbol-circle'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_1'
              title={user.Name}
              onClick={() => setUser(user)}
              key={`cw7-item-${index}`}
            >
              <span className={clsx('symbol-label fw-bold', user.Class.split(' ').join('-bg-'))}>
                {user.Name[0]}
              </span>
            </div>
          ))}

          <UserInfo user={user} />


        </div>
      </div>
    </div>
  )
}
export {CardsWidget7}
