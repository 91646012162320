
import APIKey from '../modules/API-Integration/components/API-Key'
import APISecret from '../modules/API-Integration/components/API-Secret'

const ApiIntegerationDoc = () => {
  const apikey = 'f361da49-f06d-41f6-a370-882f06154e7e'
  const apiSecret = 'f3612f154d9'
  return (
    <div className='w-100 d-flex justify-content-center align-items-center'>
      <div className='card w-lg-50 p-6  d-flex flex-column align-items-center align-items-md-start text-center text-md-start'>
        <h1 className='mb-5'>API Key</h1>
        <div className='mb-5'>Use this key in your application to integrate with CreditScore</div>
        <APIKey APIkey={apikey} label={'API Key'} />
        <APISecret APIsecret={apiSecret} label={'API Secret'} />
      </div>
    </div>
  )
}

export default ApiIntegerationDoc
