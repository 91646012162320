const LoadingResults = () => {
  return (
    <div className='card w-75 p-5 d-flex flex-row justify-content-center py-20'>
      <div className='d-flex flex-column align-items-center justify-content-around'>
        <img className='w-25 h-75 mb-10' src='media/illustrations/sketchy-1/9.png' alt='loading' />
        <div className='loaderContainer mb-10'>
            <div className='loaderBar'></div>
          </div>
        <h3 className='text-gray-700'>
          AI is running your data and we will notify you with the results on your registered email{' '}
          <span className='text-primary'>admin@company.com</span>
        </h3>
      </div>
    </div>
  )
}

export default LoadingResults
